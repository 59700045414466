import { Link } from "react-router-dom";

const ButtonLink = ({ className, ...rest }) => {
  return (
    <Link
      className={`${className} uppercase flex justify-center py-2 px-4 border border-transparent text-sm font-bold rounded-md text-white bg-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
      {...rest}
    />
  );
};
export default ButtonLink;
