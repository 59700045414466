import { Routes, Route, useNavigate, useParams } from 'react-router-dom'
import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'
import BookingEnd from '../../Booking/components/BookingEnd'
import BookingRouter from '../../Booking/containers/BookingRouter'
import {
  MEDEO_ENCOUNTER_TYPE_BOOKING,
  MEDEO_ENCOUNTER_TYPE_SYSTEM,
  MEDEO_ENCOUNTER_ORIGIN_KIOSK,
  MEDEO_ENCOUNTER_ORIGIN_SYSTEM,
  MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE,
  MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM,
  MEDEO_PROCEDURE_REQUEST_TELECONSULTATION_CODE,
} from '../../Shared/codes'
import useSave from '../../Shared/hooks/useSave'
import useSearch from '../../Shared/hooks/useSearch'
import PatientFile from './PatientFile'
import ExamenClinique from '../../ExamenClinique/containers/ExamenClinique'
import useLazySearch from '../../Shared/hooks/useLazySearch'
import Visio from '../../ExamenClinique/components/Visio'
import { getOrganizationId } from '../../Auth/utils'
import { getIdByReference } from '../../Shared/utils'
import { selectTLCDevices } from '../../Device/utils'
import useUserInfo from '../../Auth/containers/UserInfoProvider'
/*global $_ipm */

export const PatientContext = createContext([])

const Dashboard = () => {
  const { patientId } = useParams()
  const [search] = useLazySearch()
  const [procedureRequest, setProcedureRequest] = useState(null)
  const [appointment, setAppointment] = useState(null)
  const { data } = useSearch('Patient', {
    _id: patientId,
  })
  const { data: procedureRequestData } = useSearch('ProcedureRequest', {
    status: 'active',
    subject: patientId,
    code: {
      $or: [
        MEDEO_PROCEDURE_REQUEST_TELECONSULTATION_CODE,
        MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE,
      ],
    },
  })

  const { data: appointmentData } = useSearch('Appointment', {
    status: 'booked',
    patient: `Patient/${patientId}`,
  })

  const practitionerId = appointment?.participant
    ?.find(p => p.actor.reference.includes('Practitioner'))
    .actor?.reference.split('/')[1]
  const { data: practitionerData } = useSearch('Practitioner', {
    _id: practitionerId,
  })
  const practitioner = practitionerData?.Practitioner?.[0] ?? null

  useEffect(() => {
    setProcedureRequest(procedureRequestData?.ProcedureRequest?.[0])
  }, [procedureRequestData, setProcedureRequest])

  useEffect(() => {
    setAppointment(appointmentData?.Appointment?.[0])
  }, [appointmentData, setAppointment])

  const [patient] = data?.Patient ?? []
  const [save] = useSave()
  const navigate = useNavigate()

  const [currentEncounter, setCurrentEncounter] = useState(null)

  const dir = useMemo(
    () => [
      'C:\\ipmKiosk\\logiciels\\chrome-win',
      'C:\\Program Files\\SKV2-Borne\\SKChrome-win',
    ],
    []
  )

  const { userInfo } = useUserInfo()
  const organizationId = getOrganizationId(userInfo)

  const { data: orgData } = useSearch('Organization', { _id: organizationId })

  const { data: orgDevices } = useSearch('Device', {
    organization: organizationId,
  })

  const organization = orgData?.Organization?.[0]
  const organizationName = `${organization?.name} (${organization?.address?.[0]?.city})`

  const joinTLC = useCallback(
    async preconsultationId => {
      // we compute the url with the procedureRequest or with arg
      const encounterId =
        procedureRequest?.code?.coding?.find(
          c => c.system === MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM
        )?.code === MEDEO_PROCEDURE_REQUEST_TELECONSULTATION_CODE
          ? getIdByReference(procedureRequest?.context?.reference)
          : preconsultationId

      const prefixeUrl =
        process.env.REACT_APP_FHIR_BASE_URL ===
        'https://production.fhir.medeo.io/fhir'
          ? ''
          : 'develop.'

      if (encounterId == null) return

      const tlcDevices = selectTLCDevices(orgDevices.Device)
      const searchParams = new URLSearchParams()
      tlcDevices.forEach(type => searchParams.append('device', type))

      const origin = typeof $_ipm !== 'undefined' ? 'kiosk_ipm' : 'kiosk'
      searchParams.append('origin', origin)

      const url = `${prefixeUrl}medeo.care/patient/${patientId}/teleconsultation/${encounterId}/identifier/${patientId}/organizationName/${organizationName}?${searchParams.toString()}`

      // we have to wait for the procedureRequest to be updated
      if (typeof $_ipm !== 'undefined') {
        // below we call chrome multiple times, but only one has the right path.
        dir.forEach(dir => {
          $_ipm.call(
            'system.run',
            'cmd',
            '/c set GOOGLE_API_KEY="no" & set GOOGLE_DEFAULT_CLIENT_ID="no" & set GOOGLE_DEFAULT_CLIENT_SECRET="no" & cd ""' +
              dir +
              `"" & chrome.exe "${url}" --start-fullscreen`,
            '',
            1
          )
        })
      } else {
        window.open(`https://${url}`)
      }
    },
    [dir, organizationName, patientId, procedureRequest, orgDevices]
  )

  const handleClickBooking = useCallback(async () => {
    const encounter = {
      resourceType: 'Encounter',
      status: 'in-progress',
      period: {
        start: new Date().toISOString(),
      },
      type: [
        {
          coding: [
            {
              system: MEDEO_ENCOUNTER_TYPE_SYSTEM,
              code: MEDEO_ENCOUNTER_TYPE_BOOKING,
            },
          ],
        },
        // We add an origin type in order to be able to distinguish
        // between the appointments which were taken from here (kiosk) and the others.
        {
          coding: [
            {
              system: MEDEO_ENCOUNTER_ORIGIN_SYSTEM,
              code: MEDEO_ENCOUNTER_ORIGIN_KIOSK,
            },
          ],
        },
      ],
      subject: { reference: `Patient/${patientId}` },
    }

    const createdEncounter = await save(encounter)
    setCurrentEncounter(createdEncounter)
    navigate(`/dashboard/${patientId}/booking`)
  }, [patientId, navigate, save])

  const handleFinishBooking = useCallback(async () => {
    const procedureRequestData = await search('ProcedureRequest', {
      status: 'active',
      subject: patientId,
      code: {
        $or: [
          MEDEO_PROCEDURE_REQUEST_TELECONSULTATION_CODE,
          MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE,
        ],
      },
    })
    const appointmentData = await search('Appointment', {
      status: 'booked',
      patient: `Patient/${patientId}`,
    })
    setAppointment(appointmentData?.Appointment?.[0])
    setProcedureRequest(procedureRequestData?.ProcedureRequest?.[0])
    navigate('./end')
  }, [setProcedureRequest, search, patientId, navigate])

  const handleFinishPreconsultation = useCallback(
    async preconsultationId => {
      const procedureRequestData = await search('ProcedureRequest', {
        status: 'active',
        subject: patientId,
        code: {
          $or: [
            MEDEO_PROCEDURE_REQUEST_TELECONSULTATION_CODE,
            MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE,
          ],
        },
      })
      setProcedureRequest(procedureRequestData?.ProcedureRequest?.[0])
      navigate(`/dashboard/${patientId}/visio/${preconsultationId}`)
      joinTLC(preconsultationId)
    },
    [setProcedureRequest, search, patientId, joinTLC, navigate]
  )

  const handleBackVisio = useCallback(async () => {
    const procedureRequestData = await search('ProcedureRequest', {
      status: 'active',
      subject: patientId,
      code: {
        $or: [
          MEDEO_PROCEDURE_REQUEST_TELECONSULTATION_CODE,
          MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE,
        ],
      },
    })
    setProcedureRequest(procedureRequestData?.ProcedureRequest?.[0])
    navigate(`/dashboard/${patientId}`)
  }, [setProcedureRequest, search, patientId, navigate])

  return (
    <PatientContext.Provider value={[patient]}>
      <Routes>
        <Route
          path="booking/*"
          element={
            <BookingRouter
              patient={patient}
              encounter={currentEncounter}
              onFinish={handleFinishBooking}
            />
          }
        />
        <Route
          path="examen-clinique/*"
          element={
            <ExamenClinique
              patient={patient}
              practitioner={practitioner}
              procedureRequest={procedureRequest}
              onFinish={handleFinishPreconsultation}
              currentOrganization={organization}
            />
          }
        />
        <Route
          path="/"
          element={
            <PatientFile
              onClick={handleClickBooking}
              procedureRequest={procedureRequest}
              joinTLC={joinTLC}
              patient={patient}
              appointment={appointment}
              practitioner={practitioner}
            />
          }
        />
        <Route
          path="visio/:encounterId"
          element={
            <Visio
              onBack={handleBackVisio}
              onExit={() => {
                navigate('/')
              }}
              patientId={patient?.id}
            />
          }
        />
        <Route path="end" element={<BookingEnd />} />
        {/* the BookingEnd should be pass in the BookingRouter */}
      </Routes>
    </PatientContext.Provider>
  )
}

export default Dashboard
