import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HeaderButtonLink = ({ children, className, to, icon, ...rest }) => {
  return (
    <Link
      to={to}
      className="text-blue-600 w-10 h-10 flex justify-center items-center"
      {...rest}
    >
      <FontAwesomeIcon size="2x" icon={icon} />
    </Link>
  );
};

export default HeaderButtonLink;
