import './App.css'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import moment from 'moment'
import Footer from './Shared/components/Footer'
import Lobby from './Shared/containers/Lobby'
import CreatePatient from './CreatePatient/containers/CreatePatient'
import AuthContainer from './Auth/containers/Auth'
import Dashboard from './Dashboard/components/Dashboard'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffectOnce } from 'react-use'
import { installMomentLocales } from './locale'
import useNotifyOnCardRead from './CreatePatient/hooks/useNotifyOnCardRead'
import useFullStory from './Analytics/useFullStory'
import { UserInfoProvider } from './Auth/containers/UserInfoProvider'
import { initAnalytics } from './Analytics'

const stripe = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)
const useMoment = () => {
  const language = 'fr'
  useEffectOnce(() => {
    installMomentLocales()
    moment.locale(language)
  })
}

function App() {
  const notification = useNotifyOnCardRead()
  initAnalytics()
  useFullStory()
  useMoment()

  return (
    <BrowserRouter>
      <Elements stripe={stripe}>
        <div className="bg-gray-200 h-screen flex flex-col">
          {/* UserInfo handle the authentication with Amplify and Cognito */}
          <UserInfoProvider>
            <div className="h-full flex flex-col">
              <div className="flex flex-1 flex-col router">
                <Routes>
                  <Route element={<Lobby />} path="/" />
                  <Route element={<AuthContainer />} path="auth/*" />
                  <Route element={<CreatePatient />} path="create/*" />
                  <Route
                    element={<Dashboard />}
                    path="dashboard/:patientId/*"
                  />
                </Routes>
              </div>
              <Footer />
            </div>
          </UserInfoProvider>
          {notification && (
            <div className="text-white px-6 py-4 border-0 rounded absolute right-0 bottom-0 mb-4 bg-green-500">
              <span className="inline-block align-middle mr-8">
                {notification}
              </span>
            </div>
          )}
        </div>
      </Elements>
    </BrowserRouter>
  )
}

export default App
