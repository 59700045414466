import React from "react";
import InputWithSubmit from "../../Shared/components/InputWithSubmit";
import Form from "../../Shared/components/Form";
import Hero from "../../Shared/components/Hero";
import Help from "../../Shared/components/Help";

const Mail = ({ error, onChange, onSubmit, value }) => {
  return (
    <Form id="username" onSubmit={onSubmit}>
      <div>
        <Hero>Veuillez rentrer un identifiant</Hero>
        <Help>
          Votre identifiant peut être votre numéro de sécurité sociale ou votre
          téléphone.
        </Help>
      </div>
      <div>
        <InputWithSubmit
          id="username"
          name="username"
          type="text"
          autoComplete="off"
          className="fs-block"
          value={value}
          required
          onChange={onChange}
        />
        <div className="mt-4 flex  flex-row justify-center">
          <div className="text-red-600 font-bold">{error}</div>
        </div>
      </div>
      <div />
    </Form>
  );
};

export default Mail;
