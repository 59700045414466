import React, { useState, useEffect, useRef } from "react";
/* global $_ipm */

const Input = ({ className, label, ...rest }) => {
  const [hasRun, setHasRun] = useState(false);
  const ref = useRef(null);
  const type = rest.type ?? "text";
  useEffect(() => {
    if (!hasRun) {
      if (typeof $_ipm !== "undefined") {
        $_ipm.taboskbd.showLayout(type);
      }
      ref.current.focus();
      setHasRun(true);
    }
  }, [setHasRun, hasRun, type]);

  return (
    <div className={"mt-10"}>
      <label
        className={label ? "block text-xs font-medium text-gray-700 mb-5" : ""}
        htmlFor={rest.id}
      >
        {label}
      </label>
      <input
        ref={ref}
        onClick={() => {
          if (typeof $_ipm !== "undefined") {
            $_ipm.taboskbd.showLayout(type);
          }
        }}
        {...rest}
        className={`fs-block appearance-none rounded-md relative h-10 w-full px-3 py-2 border border-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${className}`}
      />
    </div>
  );
};

export default Input;
