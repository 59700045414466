import React, { useCallback, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import image from '../../Images/Asset 1@3x@1x.png'
import useUserInfo from '../../Auth/containers/UserInfoProvider'

/* global $_ipm */
function Lobby() {
  const { userInfo } = useUserInfo()
  const navigate = useNavigate()

  const start = useCallback(() => {
    navigate('/auth')
  }, [navigate])

  useEffect(() => {
    if (typeof $_ipm !== 'undefined') {
      // If the next page is not contained in the list, the keyboard is hidden
      $_ipm.taboskbd.hide()
    }
  }, [])

  return (
    <div
      onClick={start}
      className="flex flex-col pt-8 flex-1 items-center justify-center"
    >
      <div>
        <h1 className="text-2xl font-bold">
          Bienvenue chez {userInfo?.organization?.name}
        </h1>
      </div>
      <div className="mt-2">
        Consulter un médecin depuis votre pharmacie via la plateforme Medeo
      </div>
      <div className="mt-10 flex justify-center ">
        <img style={{ height: '350px' }} src={image} alt="" />
      </div>
      <div className="mt-8 py-2">
        <Link
          to="auth"
          className="border-2 font-black border-blue-400 mt-10 mb-6 px-8 py-2 text-blue-400 rounded-md uppercase"
        >
          Touchez l'écran pour démarrer
        </Link>
      </div>
      <div />
    </div>
  )
}

export default Lobby
