import { useEffect } from 'react'
import { initFullStory } from './index'
import * as FullStory from '@fullstory/browser'
import useUserInfo from '../Auth/containers/UserInfoProvider'

const useFullStory = () => {
  const { userInfo } = useUserInfo()
  useEffect(() => {
    if (userInfo?.organization != null) {
      initFullStory(userInfo.organization.id)
      FullStory.identify(userInfo.organization.id, {
        displayName: userInfo.organization.name,
        email: userInfo.organization.telecom?.find?.(
          telecom => telecom.system === 'email'
        )?.value,
      })
    }
  }, [userInfo])
}
export default useFullStory
