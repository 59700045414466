import defaultPractitionerImg from "../defaultPractitionerHead.png";
import { ESANTE_OCCUPATION_SYSTEM } from "../Shared/codes";

//esante codes
export const GENERAL_PRACTITIONER_ESANTE = "10";
export const PHARMACIST_ESANTE = "21";
export const AUDIOPROSTHESIST_ESANTE = "26";
export const OPTICIAN_ESANTE = "28";
export const DENTAL_ASSISTANT_ESANTE = "31";
export const MEDICAL_PHYSICIST_ESANTE = "32";
export const NURSING_ASSISTANT_ESANTE = "35";
export const AMBULANCE_DRIVER_ESANTE = "36";
export const CHILDCARE_ASSISTANT_ESANTE = "37";
export const PHARMACEUTICAL_ASSISTANT_HOSPITAL_ESANTE = "38";
export const PHARMACEUTICAL_ASSISTANT_DISPENSARY_ESANTE = "39";
export const DENTIST_ESANTE = "40";
export const SOCIAL_CARE_ASSISTANT_ESANTE = "41";
export const PERSONAL_CARER_ASSISTANT_ESANTE = "42";
export const SOCIAL_INTERVENTION_ASSISTANT_ESANTE = "43";
export const PSYCHOLOGICAL_ASSISTANT_ESANTE = "47";
export const MIDWIFE_ESANTE = "50";
export const NURSE_ESANTE = "60";
export const PSYCHIATRIST_NURSE_ESANTE = "69";
export const PHYSIOTHERAPIST_ESANTE = "70";
export const OSTEAOPATH_ESANTE = "71";
export const PSYCHOTHERAPIST_ESANTE = "72";
export const CHIROPRACTOR_ESANTE = "73";
export const PODIATRIST_ESANTE = "80";
export const ORTHOPROSTHESIST_ESANTE = "81";
export const PEDORTHIST_ESANTE = "82";
export const ORTHOPEDIST_ESANTE = "83";
export const OCULARIST_ESANTE = "84";
export const EPITHESIST_ESANTE = "85";
export const MEDICAL_LAB_ASSISTANT_ESANTE = "86";
export const SPEECH_THERAPIST_ESANTE = "91";
export const ORTHOPTIST_ESANTE = "92";
export const PSYCHOLOGIST_ESANTE = "93";
export const OCCUPATIONAL_THERAPIST_ESANTE = "94";
export const DIETITIAN_ESANTE = "95";
export const PSYCHOMOTOR_THERAPIST_ESANTE = "96";
export const GENETIC_ADVISOR_ESANTE = "97";
export const RADIO_OPERATOR = "98";
export const DOMESTIC_HELPER_ESANTE = "350";
export const SECRETARY_ESANTE = "10001";
export const MEDICAL_ASSISTANT_ESANTE = "10002";

export const getSpecialtyCodingFromSystem = (practitionerRole, system) => {
  return practitionerRole?.specialty?.find(
    spe => spe.coding?.[0].system === system
  )?.coding?.[0];
};

export const displayPractitionerImg = practitioner => {
  let photo = [];
  let type = [];
  if (practitioner) {
    photo = practitioner.photo?.[0]?.data;
    type = practitioner.photo?.[0]?.contentType;
  }

  if (!photo?.[0]) {
    return defaultPractitionerImg;
  }
  return `data:${type};base64,${photo}`;
};

export const displayPractitionerActiveRole = (roles, fallback = "Médecin") => {
  // Take the first role (even if the practitioner has multiple)
  const activeRole = roles && roles.length ? roles[0] : null;
  const mesEsanteRole = getSpecialtyCodingFromSystem(
    activeRole,
    ESANTE_OCCUPATION_SYSTEM
  );

  if (activeRole != null && mesEsanteRole != null) {
    return getMedeoRoleFromGouvCode(mesEsanteRole.code);
  }

  return fallback;
};

export const getMedeoRoleFromGouvCode = code => {
  switch (code) {
    case GENERAL_PRACTITIONER_ESANTE:
      return "Médecin";
    case PHARMACIST_ESANTE:
      return "Pharmacien";
    case AUDIOPROSTHESIST_ESANTE:
      return "Audioprothésiste";
    case OPTICIAN_ESANTE:
      return "Opticien-Lunetier";
    case DENTAL_ASSISTANT_ESANTE:
      return "Assistant dentaire";
    case MEDICAL_PHYSICIST_ESANTE:
      return "Physicien médical";
    case NURSING_ASSISTANT_ESANTE:
      return "Aide-soignant";
    case AMBULANCE_DRIVER_ESANTE:
      return "Ambulancier";
    case CHILDCARE_ASSISTANT_ESANTE:
      return "Auxiliaire de puériculture";
    case PHARMACEUTICAL_ASSISTANT_HOSPITAL_ESANTE:
      return "Préparateur en pharmacie hospitalière";
    case PHARMACEUTICAL_ASSISTANT_DISPENSARY_ESANTE:
      return "Préparateur en pharmacie (officine)";
    case DENTIST_ESANTE:
      return "Chirurgien-Dentiste";
    case SOCIAL_CARE_ASSISTANT_ESANTE:
      return "Assistant de service social";
    case PERSONAL_CARER_ASSISTANT_ESANTE:
      return "Auxiliaire de vie sociale";
    case SOCIAL_INTERVENTION_ASSISTANT_ESANTE:
      return "Technicien de l'intervention sociale et familiale";
    case PSYCHOLOGICAL_ASSISTANT_ESANTE:
      return "Aide médico-psychologique";
    case MIDWIFE_ESANTE:
      return "Sage-Femme";
    case NURSE_ESANTE:
      return "Infirmier";
    case PSYCHIATRIST_NURSE_ESANTE:
      return "Infirmier psychiatrique";
    case PHYSIOTHERAPIST_ESANTE:
      return "Masseur-Kinésithérapeute";
    case OSTEAOPATH_ESANTE:
      return "Ostéopathe";
    case PSYCHOTHERAPIST_ESANTE:
      return "Psychothérapeute";
    case CHIROPRACTOR_ESANTE:
      return "Chiropracteur";
    case PODIATRIST_ESANTE:
      return "Pédicure-Podologue";
    case ORTHOPROSTHESIST_ESANTE:
      return "Orthoprothésiste";
    case PEDORTHIST_ESANTE:
      return "Podo-Orthésiste";
    case ORTHOPEDIST_ESANTE:
      return "Orthopédiste-Orthésiste";
    case OCULARIST_ESANTE:
      return "Oculariste";
    case EPITHESIST_ESANTE:
      return "Epithésiste";
    case MEDICAL_LAB_ASSISTANT_ESANTE:
      return "Technicien de laboratoire médical";
    case SPEECH_THERAPIST_ESANTE:
      return "Orthophoniste";
    case ORTHOPTIST_ESANTE:
      return "Orthoptiste";
    case PSYCHOLOGIST_ESANTE:
      return "Psychologue";
    case OCCUPATIONAL_THERAPIST_ESANTE:
      return "Ergothérapeute";
    case DIETITIAN_ESANTE:
      return "Diététicien";
    case PSYCHOMOTOR_THERAPIST_ESANTE:
      return "Psychomotricien";
    case GENETIC_ADVISOR_ESANTE:
      return "Conseiller en génétique";
    case RADIO_OPERATOR:
      return "Manipulateur ERM";
    case DOMESTIC_HELPER_ESANTE:
      return "Aide à domicile";
    case SECRETARY_ESANTE:
      return "Secrétaire";
    case MEDICAL_ASSISTANT_ESANTE:
      return "Assistant médicale";
    default:
      return "Praticien";
  }
};
