import React, {
  useContext,
  createContext,
  useEffect,
  useState,
  useCallback,
} from 'react'
import CustomSignIn from './CustomSignIn'
import Spinner from '../../Shared/components/Spinner'
import useSearch from '../../Shared/hooks/useSearch'
import { Auth } from 'aws-amplify'
import mixpanel from 'mixpanel-browser'
import useMixpanel from '../../Analytics/useMixpanel'

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  protocol: 'https',
  api_host: 'https://api.mixpanel.com',
})

const UserInfoContext = createContext([])

const useDelayed = (timeout = 2000) => {
  const [delayed, setDelayed] = useState(true)
  useEffect(() => {
    let handle = setTimeout(() => {
      setDelayed(false)
    }, timeout)
    return () => clearTimeout(handle)
  }, [timeout])
  return delayed
}

const useSignIn = () => {
  const [status, setStatus] = useState('loading')

  const [, setUserInfo] = useContext(UserInfoContext)
  // signIn status is either 'loading', 'No current user' or logged
  // Amplify is promised based so when the component mounts there is a short loading time
  // the user can either be logged or not, so we display the spinner during this time
  const signIn = useCallback(
    () =>
      Promise.all([Auth.currentSession(), Auth.currentAuthenticatedUser()])
        .then(([session, user]) => {
          let groups = []
          if (session?.accessToken?.payload?.['cognito:groups']) {
            groups = session.accessToken.payload['cognito:groups']
          }

          setUserInfo({
            token: session.accessToken.getJwtToken(),
            attributes: user.attributes,
            cognitoGroups: groups,
          })
          setStatus('signIn')
        })
        .catch(e => {
          if (e === 'No current user') {
            setStatus(e)
          }
        }),
    [setUserInfo]
  )
  useEffect(() => {
    signIn().then()
  }, [signIn])

  return { status, signIn }
}
const useUserInfo = () => {
  const [userInfo, setUserInfo] = useContext(UserInfoContext)

  const setOrganization = useCallback(
    organization => {
      setUserInfo(u => ({ ...u, organization }))
    },
    [setUserInfo]
  )

  return {
    userInfo,
    setOrganization,
  }
}

const useFetchOrganizationOnSignIn = () => {
  const { userInfo, setOrganization } = useUserInfo()

  const { data } = useSearch('Organization', {
    _id: userInfo?.attributes['custom:orgId'],
  })

  useEffect(() => {
    if (!userInfo?.organization && data?.Organization?.[0]) {
      const organization = data?.Organization?.[0]
      setOrganization(organization)
    }
  }, [userInfo, setOrganization, data])
}

const RequireSignIn = ({ children }) => {
  const { signIn, status } = useSignIn()
  useFetchOrganizationOnSignIn()
  // initialize Mixpanel and sets everything up
  useMixpanel()
  // delayed will be false on mount and wait for [timeout] before being true
  // it is used to display to the fallback component for a minimum amount of time
  const delayed = useDelayed()
  // display the spinner for a minimum of time
  // to prevent flickering UI

  // call the signIn method when the user has validated the form
  const handleStateChange = e => {
    if (e === 'signedIn') {
      signIn().then()
    }
  }
  if (delayed === true || status === 'loading') {
    return <Spinner />
  } else if (status === 'No current user') {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 ">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg max-w-md w-full space-y-8 p-4">
          <CustomSignIn onStateChange={handleStateChange} />
        </div>
      </div>
    )
  } else {
    return children
  }
}

export const UserInfoProvider = ({ children }) => {
  const value = useState(null)

  return (
    <UserInfoContext.Provider value={value}>
      <RequireSignIn>{children}</RequireSignIn>
    </UserInfoContext.Provider>
  )
}

export default useUserInfo
