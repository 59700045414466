import * as FullStory from '@fullstory/browser'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

// Analytics
// - Sentry: bugs and crashlytics
// - Fullstory: digital experience analytics
// - Mixpanel: events reportings

export const initSentry = () => {
  if (process.env.REACT_APP_SENTRY_DSN == null) {
    console.warn(`[Medeo] REACT_APP_SENTRY_DSN is not configured...
please set REACT_APP_SENTRY_DSN in your .env
`)
    return
  }
  if (process.env.REACT_APP_FULLSTORY_ORG_ID == null) {
    console.warn(`[Kiosk] REACT_APP_FULLSTORY_ORG_ID is not set...
FullStory and Sentry won't be connected.
Please set REACT_APP_FULLSTORY_ORG_ID in your .env
`)
    return
  }
  if (process.env.REACT_APP_SENTRY_ORG_SLUG == null) {
    console.warn(`[Kiosk] REACT_APP_SENTRY_ORG_SLUG is not set...
FullStory and Sentry won't be connected.
Please set REACT_APP_SENTRY_ORG_SLUG in your .env
`)
    return
  }

  // REACT_APP_SENTRY_RELEASE is created during the build
  // if it does not exist do not quit the setup !
  // it probably means we are in develop
  if (process.env.REACT_APP_SENTRY_RELEASE == null) {
    console.warn(`[Medeo] REACT_APP_SENTRY_RELEASE is not set...`)
  }
  // set up sentry with both error and performance data
  // see https://sentry.io/medeo/react/getting-started/javascript-react/
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // REACT_APP_SENTRY_RELEASE is generated during build using the commit revId
    // this env var is not meant to be on your .env
    release: null, //process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [
      new Integrations.BrowserTracing(),
      //   new SentryFullStory(process.env.REACT_APP_SENTRY_ORG_SLUG),
    ],
    // limit depth to 5 as higher values cause the breadcrumbs to be over 100kB
    // this results in the bug not being sent to sentry !
    normalizeDepth: 5,
    tracesSampleRate: 0.1,
  })
}

/**
 * initFullStory is used to save sessions of all user in production
 * we filter to get only user which are outside of Medeo company
 * @param organizationId
 */
export const initFullStory = organizationId => {
  if (process.env.REACT_APP_FULLSTORY_ORG_ID == null) {
    console.warn(
      "[Kiosk] REACT_APP_FULLSTORY_ORG_ID is not set. FullStory won't be initialized"
    )
    return
  }

  const orgaIgnored = ignoredOrganization.some(o => o === organizationId)

  if (!orgaIgnored)
    FullStory.init({
      orgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
      // devMode: process.env.NODE_ENV !== 'production',
    })
}

/**
 * analytics are triggered only in production
 */
export const initAnalytics = () => {
  if (process.env.NODE_ENV !== 'production') {
    console.warn(
      `[Kiosk] Sentry is not configured to run in ${process.env.NODE_ENV}`
    )
    return
  }
  initSentry()
}
export const configureAnalytics = practitioner => {
  Sentry.configureScope(function(scope) {
    const email = practitioner.telecom?.find?.(t => t.system === 'email')?.value
    const [name] = practitioner.name ?? []
    const username = `${name?.family} ${name?.given?.[0]}`
    scope.setUser({ email, id: practitioner.id, username })
  })
}

// this array regroup all id of Medeo Organization in our production db
// It's use to prevent save session in FullStory for Medeo member
export const ignoredOrganization = [
  '2121', //MedeoBiz
  '2726', //MedeoDev
  '9569', //contact+demophar@medeo-health.com contact+demoinf@medeo-health.com
  '9575', //contact+demomedoc@medeo-health.com
  '8912', //gabriel+prodoc@medeo-health.com
  '3592', //MedeoProduit
  '19254', //coline+test@medeo-health.com
  '8892', //yacine+prodoc@medeo-health.com
]
