import React from 'react'
import Form from '../../Shared/components/Form'
import Hero from '../../Shared/components/Hero'
import Help from '../../Shared/components/Help'
import InputWithSubmit from '../../Shared/components/InputWithSubmit'

const NIR = ({ value, onChange, onSubmit }) => {
  return (
    <Form id="nir" onSubmit={onSubmit}>
      <div>
        <Hero>Quel est votre numéro de sécurité sociale ?</Hero>
        <Help>
          Vous pourrez ajouter plus tard votre numéro dans votre dossier.
        </Help>
      </div>
      <div className="flex flex-col">
        <InputWithSubmit
          id="nir-number"
          className="w-1/2 fs-block"
          name="nir"
          autoComplete="off"
          type="number"
          value={value}
          disabled={value.length !== 13}
          onChange={onChange}
        />
        <button
          onClick={onSubmit}
          className="mt-4 text-md text-center font-medium text-blue-400"
        >
          Je n'ai pas de numéro de sécurité sociale
        </button>
      </div>
      <div className="h-16" />
    </Form>
  )
}

export default NIR
