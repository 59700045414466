import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import React, { useReducer } from 'react'
import Heading from '../../Shared/components/Heading'
import useLazySearch from '../../Shared/hooks/useLazySearch'
import Mail from '../components/Mail'
import HeaderButtonLink from '../../Shared/components/HeaderButtonLink'
import Spinner from '../../Shared/components/Spinner'
import StartManualLogin from '../components/StartManualLogin'
import {
  KIOSK_MANUAL_LOGIN_FAILED_EVENT,
  KIOSK_MANUALLY_LOGGED_EVENT,
} from '../../Analytics/useMixpanel'
import mixpanel from 'mixpanel-browser'

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'add-login':
      return {
        ...state,
        login: payload,
      }
    case 'patient-search':
      return {
        ...state,
        loading: true,
      }
    case 'patient-found':
      return {
        ...state,
        loading: false,
        patientFhir: payload,
        error: null,
      }
    case 'error':
      return {
        ...state,
        error: payload,
        loading: false,
      }
    default:
      return state
  }
}

/**
 * Enable user to manually (i.e not using a vitale card) login by entering
 * their phone or NIR
 */
const ManualLogin = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    login: '',
    pin: '',
    patientFhir: null,
    error: null,
  })
  const [search] = useLazySearch()

  const onSubmit = async e => {
    const formId = e.target.id
    e.preventDefault()
    switch (formId) {
      case 'username':
        dispatch({ type: 'patient-search' })
        const data = await search('Patient', {
          _filter: `(phone eq ${state.login}  or identifier eq ${state.login})`,
          _sort: '-_id',
        })

        const patient = data?.Patient?.[0]
        if (patient != null) {
          dispatch({ type: 'patient-found', payload: patient })
          mixpanel.track(KIOSK_MANUALLY_LOGGED_EVENT, {
            type: state.login.length === 13 ? 'nir' : 'phone',
          })
          navigate(`/dashboard/${patient.id}`)
        } else {
          dispatch({ type: 'error', payload: 'Aucun patient ne correspond' })
          mixpanel.track(KIOSK_MANUAL_LOGIN_FAILED_EVENT, {
            type: state.login.length === 13 ? 'nir' : 'phone',
          })
        }
        break
      default:
        break
    }
  }

  // If we are on the main page already, redirect to root while going back.
  // Else, redirect to start page.
  const goBackLink = location.pathname === '/auth/manual' ? '/' : '/auth/manual'

  const onChange = e => dispatch({ type: 'add-login', payload: e.target.value })

  return (
    <div>
      <div className="p-6 bg-white flex flex-row justify-between items-center shadow-sm border-b-2">
        <HeaderButtonLink to={goBackLink} icon={faChevronLeft} />
        <Heading>Connexion</Heading>
        <HeaderButtonLink to={goBackLink} icon={faTimes} />
      </div>
      <div className="m-10 flex-1 flex">
        <Routes>
          <Route path="/" element={<StartManualLogin />} />
          <Route
            path="mail"
            element={
              <Mail
                error={state.error}
                value={state.login}
                onChange={onChange}
                onSubmit={onSubmit}
              />
            }
          />
        </Routes>
      </div>
      {state.loading && <Spinner />}
    </div>
  )
}

export default ManualLogin
