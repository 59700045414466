import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import ManualLogin from './ManualLogin'
import CardAuthentication from './CardAuthentication'
import mixpanel from 'mixpanel-browser'
import { KIOSK_START_EVENT } from '../../Analytics/useMixpanel'

const Auth = () => {
  useEffect(() => {
    mixpanel.track(KIOSK_START_EVENT)
  }, [])
  return (
    <div className="router flex-1 flex">
      <Routes>
        <Route element={<CardAuthentication />} path="/" />
        <Route element={<ManualLogin />} path="/manual/*" />
      </Routes>
    </div>
  )
}

export default Auth
