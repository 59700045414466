import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/stable/symbol";
import "./fonts/Manrope-Regular.ttf";

import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import "./index.css";
import App from "./App";

// amplify needs to be configured otherwise it will complain on the console
// see https://docs.amplify.aws/lib/auth/getting-started/q/platform/js#configure-your-application
Amplify.configure(awsconfig);


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
