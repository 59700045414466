import { getOrganizationId } from '../Auth/utils'
import useSearch from '../Shared/hooks/useSearch'
import { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'
import useUserInfo from '../Auth/containers/UserInfoProvider'

export const KIOSK_START_EVENT = 'kiosk-start'
export const KIOSK_CV_INSERTED_EVENT = 'cv-inserted'
export const KIOSK_CV_STATUS_UPDATED_EVENT = 'cv-status-updated'
export const KIOSK_MANUALLY_LOGGED_EVENT = 'kiosk-manually-logged'
export const KIOSK_MANUAL_LOGIN_FAILED_EVENT = 'kiosk-manually-logged'
export const KIOSK_PATIENT_CREATED_EVENT = 'kiosk-patient-created'
const useMixpanel = () => {
  const { userInfo } = useUserInfo()
  const organizationId = getOrganizationId(userInfo)
  const { data, loading } = useSearch('PractitionerRole', {
    _include: 'PractitionerRole:practitioner',
    organization: organizationId,
    specialty: 'administrator',
    _count: 1,
  })

  useEffect(() => {
    if (loading === true) {
      return
    }
    const practitioner = data?.Practitioner?.[0]
    if (practitioner == null) return
    // these elements are legacy,
    // they are primarily use to register details for new users on Mixpanel
    const lastName = practitioner?.name?.[0]?.family
    const firstName = practitioner?.name?.[0]?.given?.[0]
    const email = practitioner?.telecom?.find(t => t.system === 'email')?.value

    // calls to Identify can be deduped:
    // When a single user does multiple actions such as remote consultation, etc..
    // They may be asked to type their pin again.
    // we can check if the distinct id used by Mixpanel is already set or not
    const currentDistinctId = mixpanel.get_distinct_id()
    // if we switch for user A to User B, we call reset and identify the new user
    if (currentDistinctId !== practitioner.id) {
      mixpanel.reset()
      mixpanel.identify(practitioner.id)
    }
    // register makes the props available on every subsequent requests
    mixpanel.register({
      organization: organizationId,
    })
    mixpanel.people.set({
      $last_login: new Date(),
      $email: email,
      $first_name: firstName,
      $last_name: lastName,
      specialty: 'administrator',
    })
  }, [loading, data, organizationId])
}
export default useMixpanel
