/*global $_ipm*/
import { Route, Routes, useNavigate } from 'react-router-dom'
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import mixpanel from 'mixpanel-browser'
import Header from '../../Shared/components/Header'
import Informations from '../components/Informations'
import Mail from '../components/Mail'
import Phone from '../components/Phone'
import Firstname from '../components/Firstname'
import Lastname from '../components/Lastname'
import NIR from '../components/NIR'
import Gender from '../components/Gender'
import Birthdate from '../components/Birthdate'
import {
  FHIR_CONDITION_CATEGORY_SYSTEM_URL,
  FHIR_OBSERVATION_CATEGORY_SYSTEM_URL,
  FHIR_OBSERVATION_SOCIAL_HISTORY,
  FHIR_OBSERVATION_VITAL_SIGNS,
  FHIR_PROBLEM_LIST_CODE,
  LOINC_BODY_HEIGHT_CODE,
  LOINC_BODY_WEIGHT_CODE,
  LOINC_SYSTEM_URL,
  MEDEO_ALLERGY_SYSTEM,
  MEDEO_ENCOUNTER_TYPE_ADD_PATIENT,
  MEDEO_ENCOUNTER_TYPE_SYSTEM,
  MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_MEDECIN_TRAITANT_CODE,
  MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_SYSTEM,
  MEDEO_MEDICAL_HISTORY_CODE,
  MEDEO_MEDICAL_HISTORY_SYSTEM,
  MEDEO_NIR_IDENTIFIER_SYSTEM,
  MEDEO_RPPS_IDENTIFIER_SYSTEM,
  MEDEO_SURGICAL_HISTORY_CODE,
  MEDEO_SURGICAL_HISTORY_SYSTEM,
  MEDEO_VALUE_SET_MEDICAL_HISTORY_SYSTEM,
  MEDEO_VALUE_SET_SURGICAL_HISTORY_SYSTEM,
  SNOMED_DAILY_SMOKER,
  SNOMED_FINING_TOBACCO,
  SNOMED_SYSTEM_URL,
  UNITS_OF_MEASURE_SYSTEM_URL,
} from '../../Shared/codes'
import { getOrganizationId, getTokenFromAmplify } from '../../Auth/utils'
import useSave from '../../Shared/hooks/useSave'
import GeneralPractitioner from '../components/GeneralPractitioner'
import PatientFileInformations from '../components/PatientFileInformations'
import CGU from '../components/CGU'
import BinaryHealthProblems from '../components/BinaryHealthProblems'
import HealthProblems from '../components/HealthProblems'
import BinarySurgery from '../components/BinarySurgery'
import BinaryAllergies from '../components/BinaryAllergies'
import Allergies from '../components/Allergies'
import Weight from '../components/Weight'
import BinaryTobacco from '../components/BinaryTobacco'
import Height from '../components/Height'
import WaitingComponent from '../components/WaitingComponent'
import Spinner from '../../Shared/components/Spinner'
import useNotifyOnCardRead from '../hooks/useNotifyOnCardRead'
import useUserInfo from '../../Auth/containers/UserInfoProvider'
import { KIOSK_PATIENT_CREATED_EVENT } from '../../Analytics/useMixpanel'

// this array contains the rpps of practitioners that have multiple accounts
// see onSubmit for details of this array
const IGNORED_RPPS_ARRAY = [
  10001769453, // Jacquot
  10100911980, // Béligné
  10101993607, // Cheminel
]

function Redirect({ to }) {
  let navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  })
  return null
}

const hideKeyBoardIfRequired = indexOfPage => {
  // List of page indexes where there is an input to show
  const indexesWhereKeyBoard = [1, 2, 3, 6, 7, 12, 16, 18]

  if (typeof $_ipm !== 'undefined') {
    // If the next page is not contained in the list, the keyboard is hidden
    if (!indexesWhereKeyBoard.includes(indexOfPage)) $_ipm.taboskbd.hide()
  }
}

const initPatient = cardData => ({
  firstName: cardData?.firstName ?? '',
  lastName: cardData?.lastName ?? '',
  nir: cardData?.nir ?? '',
  gender: cardData?.gender ?? '',
  birthDate: cardData?.birthDate ?? '',
  phone: '',
  mail: '',
  pin: '',
  confirmation: '',
  acceptCGU: false,
  generalPractitioner: '',
  healthProblems: false,
  healthProblemValues: [],
  surgery: false,
  allergies: false,
  allergiesValues: [],
  weight: undefined,
  height: undefined,
  tobacco: false,
})

const CreatePatient = ({ location }) => {
  const navigate = useNavigate()
  const { userInfo } = useUserInfo()
  const organizationId = getOrganizationId(userInfo)
  const [save] = useSave()
  const [indexOfPage, setIndexOfPage] = useState(0)
  const [headerText, setHeaderText] = useState('Création du compte')

  // The cardData might contain the data read from the card reader.
  // If so, we can start to populate the patient state with it.
  // We use a ref here as the useEffect used for navigation on pageIndex change will otherwise
  // make us lose the information as we navigate, although we need to preserve it
  // in order to go back properly.
  const cardDataRef = useRef(location?.state?.cardData)
  const cardData = cardDataRef.current
  const [newPatient, setNewPatient] = useState(initPatient(cardData))
  const [generalPractitioner, setGeneralPractitioner] = useState(null)

  const [fhirPatient, setFhirPatient] = useState(null)
  const [consentBinaryUrl, setConsentBinaryUrl] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const notification = useNotifyOnCardRead()

  const savePatient = async () => {
    const token = await getTokenFromAmplify()
    const patient = {
      resourceType: 'Patient',
      active: true,
      identifier: [
        {
          system: MEDEO_NIR_IDENTIFIER_SYSTEM,
          value: newPatient.nir,
          type: {
            coding: [
              {
                system:
                  'http://interopsante.org/fhir/valueset/fr-patient-identifier-type',
                version: '1',
                code: 'INS-NIR',
                display: 'NIR',
              },
            ],
          },
        },
      ],
      name: [
        {
          use: 'usual',
          family: newPatient.lastName,
          given: [newPatient.firstName],
        },
      ],
      telecom: [
        {
          system: 'email',
          value: newPatient.mail,
        },
        {
          system: 'phone',
          use: 'mobile',
          value: newPatient.phone,
        },
      ],
      birthDate: newPatient.birthDate,
      gender: newPatient.gender,
      managingOrganization: { reference: `Organization/${organizationId}` },
    }
    const createdPatient = await save(patient)
    setFhirPatient(createdPatient)

    // consent
    const result = await fetch(
      `${process.env.REACT_APP_PDF_BASE_URL}/generateConsentPdf`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          patient: {
            firstName: newPatient.firstName,
            lastName: newPatient.lastName,
            phone: newPatient.phone,
            address: '',
          },
        }),
      }
    )
    const consent = await result.json()
    setConsentBinaryUrl(consent.url)
  }

  /**
   * OnSubmit function will be passed likewise to each component inside
   * the router, as each should contain a form.
   *
   * It handles the navigation to be done on form submission depending on the
   * form we are in, which we can get from the form id.
   * @param {Event} e
   */
  const onSubmit = async e => {
    const formId = e.target.id
    e.preventDefault()
    if (indexOfPage !== 7) setIndexOfPage(indexOfPage + 1)
    // Need to add this case because we removed the code pin steps. I don't trust rename all the paths.
    if (indexOfPage === 7) setIndexOfPage(10)
    switch (formId) {
      case 'cgu':
        setIsLoading(true)
        setHeaderText('Création du dossier patient')
        setNewPatient({ ...newPatient, acceptCGU: true })
        await savePatient()
        setIsLoading(false)
        break
      case 'height':
        navigate('./creating')
        break
      default:
        break
    }
  }

  const handleSubmit = useCallback(async () => {
    const savePatientInformation = async () => {
      const bundleEntries = []
      const fullUrl = `urn:uuid:${uuidv4()}`

      if (newPatient.healthProblems) {
        newPatient.healthProblemValues.map(healthProblem =>
          bundleEntries.push({
            resource: {
              resourceType: 'Condition',
              identifier: [
                {
                  type: {
                    coding: {
                      system: MEDEO_VALUE_SET_MEDICAL_HISTORY_SYSTEM,
                      code: MEDEO_MEDICAL_HISTORY_CODE,
                      display: 'Medical history',
                    },
                  },
                },
              ],
              category: {
                coding: {
                  system: FHIR_CONDITION_CATEGORY_SYSTEM_URL,
                  code: FHIR_PROBLEM_LIST_CODE,
                  display: 'Problem List Item',
                },
              },
              code: {
                coding: {
                  system: MEDEO_MEDICAL_HISTORY_SYSTEM,
                  code: healthProblem.code,
                },
                text: healthProblem.text,
              },
              subject: { reference: `Patient/${fhirPatient.id}` },
            },
            request: {
              method: 'POST',
              url: 'Condition',
            },
          })
        )
      }
      if (newPatient.allergies) {
        newPatient.allergiesValues.map(allergyValue =>
          bundleEntries.push({
            resource: {
              resourceType: 'AllergyIntolerance',
              verificationStatus: 'confirmed',
              code: {
                coding: [
                  { system: MEDEO_ALLERGY_SYSTEM, code: allergyValue.code },
                ],
                text: allergyValue.text,
              },
              clinicalStatus: 'active',
              patient: { reference: `Patient/${fhirPatient.id}` },
            },
            request: {
              method: 'POST',
              url: 'AllergyIntolerance',
            },
          })
        )
      }
      if (newPatient.surgery) {
        bundleEntries.push({
          resource: {
            resourceType: 'Condition',
            identifier: [
              {
                type: {
                  coding: {
                    system: MEDEO_VALUE_SET_SURGICAL_HISTORY_SYSTEM,
                    code: MEDEO_SURGICAL_HISTORY_CODE,
                    display: 'Surgical history',
                  },
                },
              },
            ],
            category: {
              coding: {
                system: FHIR_CONDITION_CATEGORY_SYSTEM_URL,
                code: FHIR_PROBLEM_LIST_CODE,
                display: 'Problem List Item',
              },
            },
            code: {
              coding: {
                system: MEDEO_SURGICAL_HISTORY_SYSTEM,
                code: 'has-surgery',
              },
              text: 'A été opéré',
            },
            subject: { reference: `Patient/${fhirPatient.id}` },
          },
          request: {
            method: 'POST',
            url: 'Condition',
          },
        })
      }
      if (newPatient.tobacco) {
        bundleEntries.push({
          resource: {
            resourceType: 'Observation',
            status: 'final',
            subject: { reference: `Patient/${fhirPatient.id}` },
            context: { reference: fullUrl },
            category: [
              {
                coding: [
                  {
                    system: FHIR_OBSERVATION_CATEGORY_SYSTEM_URL,
                    code: FHIR_OBSERVATION_SOCIAL_HISTORY,
                    display: 'Social History',
                  },
                ],
                text: 'Social History',
              },
            ],
            valueCodeableConcept: {
              coding: [
                {
                  system: SNOMED_SYSTEM_URL,
                  code: SNOMED_DAILY_SMOKER,
                  display: 'Smokes tobacco',
                },
              ],
            },
            code: {
              coding: {
                system: SNOMED_SYSTEM_URL,
                code: SNOMED_FINING_TOBACCO,
                display: 'Finding of tobacco use and exposure (finding)',
              },
            },
            effectiveDateTime: new Date().toISOString(),
          },
          request: {
            method: 'POST',
            url: 'Observation',
          },
        })
      }
      if (generalPractitioner != null) {
        const GPurl = `urn:uuid:${uuidv4()}`
        const GPDisplay = `${generalPractitioner?.name[0]?.family} ${generalPractitioner?.name[0]?.given?.[0]}`
        const GPRpps = generalPractitioner?.identifier?.find(
          i => i.system === MEDEO_RPPS_IDENTIFIER_SYSTEM
        )?.value
        // as of April 2022 we cannot ensure the rpps identifier to be unique in our database
        // we have several practitioners that have multiple accounts for good reasons
        // unfortunately the conditional create down below will fail for these practitioners
        // the fhir server will respond with a status 412 because of multiple rpps found
        // see http://hl7.org/fhir/http.html#ccreate for more details
        // we should prevent this from happening by forcing the practitioner resource to be unique
        // this is too big a task to be done as a bug fix
        // therefore we branch here for a special case with these practitioners

        if (!IGNORED_RPPS_ARRAY.includes(Number.parseInt(GPRpps, 10))) {
          bundleEntries.push(
            {
              resource: generalPractitioner,
              request: {
                method: 'POST',
                url: 'Practitioner',
                ifNoneExist: `identifier=${GPRpps}`,
              },
              fullUrl: GPurl,
            },
            {
              resource: {
                ...fhirPatient,
                generalPractitioner: {
                  reference: GPurl,
                  display: `Dr ${GPDisplay}`,
                  identifier: {
                    system: MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_SYSTEM,
                    value: MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_MEDECIN_TRAITANT_CODE,
                  },
                },
              },
              request: {
                method: 'PUT',
                url: `Patient/${fhirPatient.id}`,
              },
            }
          )
        }
      }
      try {
        await save({
          resourceType: 'Bundle',
          type: 'transaction',
          entry: bundleEntries.concat([
            {
              resource: {
                resourceType: 'Encounter',
                status: 'finished',
                period: {
                  start: new Date().toISOString(),
                  end: new Date().toISOString(),
                  type: [
                    {
                      coding: [
                        {
                          system: MEDEO_ENCOUNTER_TYPE_SYSTEM,
                          code: MEDEO_ENCOUNTER_TYPE_ADD_PATIENT,
                        },
                      ],
                    },
                  ],
                },
                subject: { reference: `Patient/${fhirPatient.id}` },
              },
              request: {
                method: 'POST',
                url: 'Encounter',
              },
              fullUrl: fullUrl,
            },
            {
              resource: {
                resourceType: 'Observation',
                status: 'final',
                subject: { reference: `Patient/${fhirPatient.id}` },
                context: { reference: fullUrl },
                category: [
                  {
                    coding: [
                      {
                        system: FHIR_OBSERVATION_CATEGORY_SYSTEM_URL,
                        code: FHIR_OBSERVATION_VITAL_SIGNS,
                        display: 'Vital Signs',
                      },
                    ],
                    text: 'Vital Signs',
                  },
                ],
                valueQuantity: {
                  value: newPatient.weight,
                  unit: 'kg',
                  system: UNITS_OF_MEASURE_SYSTEM_URL,
                },
                code: {
                  coding: {
                    system: LOINC_SYSTEM_URL,
                    code: LOINC_BODY_WEIGHT_CODE,
                    display: 'Body weight',
                  },
                },
                effectiveDateTime: new Date().toISOString(),
              },
              request: {
                method: 'POST',
                url: 'Observation',
              },
            },
            {
              resource: {
                resourceType: 'Observation',
                status: 'final',
                subject: { reference: `Patient/${fhirPatient.id}` },
                context: { reference: fullUrl },
                category: [
                  {
                    coding: [
                      {
                        system: FHIR_OBSERVATION_CATEGORY_SYSTEM_URL,
                        code: FHIR_OBSERVATION_VITAL_SIGNS,
                        display: 'Vital Signs',
                      },
                    ],
                    text: 'Vital Signs',
                  },
                ],
                valueQuantity: {
                  value: newPatient.height,
                  unit: 'cm',
                  system: UNITS_OF_MEASURE_SYSTEM_URL,
                },
                code: {
                  coding: {
                    system: LOINC_SYSTEM_URL,
                    code: LOINC_BODY_HEIGHT_CODE,
                    display: 'Body height',
                  },
                },
                effectiveDateTime: new Date().toISOString(),
              },
              request: {
                method: 'POST',
                url: 'Observation',
              },
            },
            {
              resource: {
                resourceType: 'Consent',
                dateTime: new Date().toISOString(),
                organization: { reference: `Organization/${organizationId}` },
                category: [
                  {
                    coding: [
                      {
                        system: 'www.medeo-health.com/patient-consent-version',
                        code: '1.1',
                      },
                    ],
                  },
                ],
                sourceAttachment: {
                  contentType: 'application/pdf',
                  url: consentBinaryUrl,
                  title: 'Consentement.pdf',
                },
              },
              request: { method: 'POST', url: 'Consent' },
            },
          ]),
        })
      } catch (e) {
        console.log(e)
      }
    }
    await savePatientInformation()
    mixpanel.track(KIOSK_PATIENT_CREATED_EVENT)
    navigate(`/dashboard/${fhirPatient?.id}`)
  }, [
    navigate,
    fhirPatient,
    consentBinaryUrl,
    newPatient,
    organizationId,
    save,
    generalPractitioner,
  ])

  // navigate is not in the dependency array because of:
  //
  useEffect(() => {
    hideKeyBoardIfRequired(indexOfPage)
    if (indexOfPage < 19 && indexOfPage >= 0) {
      navigate(`./${indexOfPage}`)
    }
    // navigate is not part of the dependency array cause of a bug
    // https://github.com/remix-run/react-router/pull/8734
    //eslint-disable-next-line
  }, [indexOfPage])

  const onBackHeader = () => {
    if (indexOfPage > 0) {
      setIndexOfPage(indexOfPage - 1)
    } else {
      if (cardData != null) navigate('../auth')
      else navigate('../auth/manual')
    }
  }

  /**
   * Add or remove an option from a list
   *
   * @param {[String]} selectedOptions: list of already selected values
   * @param {String} value: clicked value
   */
  const updateOptionList = (selectedOptions, value) => {
    const index = selectedOptions.findIndex(option => option === value)

    return index === -1
      ? selectedOptions.concat([value])
      : [
          ...selectedOptions.slice(0, index),
          ...selectedOptions.slice(index + 1),
        ]
  }

  const handleCancel = useCallback(() => {
    navigate('/auth')
  }, [navigate])

  return (
    <div>
      <Header
        cancelText={
          indexOfPage <= 10 ? (
            <>
              Vous êtes sur le point d'abandonner la création de votre compte.
              <br /> Vos données seront perdues.
            </>
          ) : (
            <>
              Vous êtes sur le point d'abandonner la création de votre dossier
              patient. <br /> Vos identifiants et code de sécurité ont bien été
              enregistrés.
            </>
          )
        }
        headerText={headerText}
        onBack={onBackHeader}
        onCancel={handleCancel}
        widthOfProgressBar={(indexOfPage / 16) * 100}
        isPrevious={indexOfPage >= 1}
      />
      <div className="m-10 flex-1 flex">
        <Routes>
          <Route
            path="0"
            element={
              <Informations
                onNext={() => {
                  if (cardData != null) setIndexOfPage(6)
                  else setIndexOfPage(1)
                }}
                cardData={cardData}
                onCancel={handleCancel}
              />
            }
          />
          <Route
            path="1"
            element={
              <Firstname
                className="fs-block"
                value={newPatient.firstName}
                onChange={e =>
                  setNewPatient({ ...newPatient, firstName: e.target.value })
                }
                onSubmit={onSubmit}
              />
            }
          />
          <Route
            path="2"
            element={
              <Lastname
                className="fs-block"
                value={newPatient.lastName}
                onChange={e =>
                  setNewPatient({ ...newPatient, lastName: e.target.value })
                }
                onSubmit={onSubmit}
              />
            }
          />
          <Route
            path="3"
            element={
              <NIR
                className="fs-block"
                value={newPatient.nir}
                onChange={e =>
                  setNewPatient({ ...newPatient, nir: e.target.value })
                }
                onSubmit={onSubmit}
              />
            }
          />
          <Route
            path="4"
            element={
              <Gender
                onClick={gender => {
                  console.log('coucou')
                  setNewPatient({ ...newPatient, gender: gender })
                  setIndexOfPage(indexOfPage => indexOfPage + 1)
                }}
                value={newPatient.gender}
              />
            }
          />
          <Route
            path="5"
            element={
              <Birthdate
                className="fs-block"
                value={newPatient.birthDate}
                onChange={date =>
                  setNewPatient({ ...newPatient, birthDate: date })
                }
                onSubmit={onSubmit}
              />
            }
          />
          <Route
            path="6"
            element={
              <Phone
                className="fs-block"
                value={newPatient.phone}
                onChange={e =>
                  setNewPatient({ ...newPatient, phone: e.target.value })
                }
                onSubmit={onSubmit}
              />
            }
          />
          <Route
            path="7"
            element={
              <Mail
                className="fs-block"
                value={newPatient.mail}
                onChange={e =>
                  setNewPatient({ ...newPatient, mail: e.target.value })
                }
                onSubmit={onSubmit}
              />
            }
          />
          <Route path="10" element={<CGU onSubmit={onSubmit} />} />
          <Route
            path="11"
            element={
              <PatientFileInformations
                onNext={() => setIndexOfPage(indexOfPage => indexOfPage + 1)}
              />
            }
          />
          <Route
            path="12"
            element={
              <GeneralPractitioner
                className="fs-block"
                value={generalPractitioner}
                onSelect={resource => setGeneralPractitioner(resource)}
                onSubmit={onSubmit}
              />
            }
          />
          <Route
            path="13"
            element={
              <BinaryHealthProblems
                onClick={healthProblemsBoolean => {
                  setNewPatient({
                    ...newPatient,
                    healthProblems: healthProblemsBoolean,
                  })
                  healthProblemsBoolean
                    ? navigate('./13-bis')
                    : setIndexOfPage(indexOfPage => indexOfPage + 1)
                }}
              />
            }
          />
          <Route
            path="13-bis"
            element={
              <HealthProblems
                value={newPatient.healthProblemValues}
                onClick={value => {
                  const updatedOptionList = updateOptionList(
                    newPatient.healthProblemValues,
                    value
                  )
                  setNewPatient({
                    ...newPatient,
                    healthProblemValues: updatedOptionList,
                  })
                }}
                onSubmit={onSubmit}
              />
            }
          />
          <Route
            path="14"
            element={
              <BinarySurgery
                onClick={surgeryBoolean => {
                  setNewPatient({ ...newPatient, surgery: surgeryBoolean })
                  setIndexOfPage(indexOfPage => indexOfPage + 1)
                }}
              />
            }
          />
          <Route
            path="15"
            element={
              <BinaryAllergies
                onClick={allergiesBoolean => {
                  setNewPatient({ ...newPatient, allergies: allergiesBoolean })
                  allergiesBoolean
                    ? navigate('./15-bis')
                    : setIndexOfPage(indexOfPage => indexOfPage + 1)
                }}
              />
            }
          />
          <Route
            path="15-bis"
            element={
              <Allergies
                value={newPatient.allergiesValues}
                onClick={value => {
                  const updatedOptionList = updateOptionList(
                    newPatient.allergiesValues,
                    value
                  )
                  setNewPatient({
                    ...newPatient,
                    allergiesValues: updatedOptionList,
                  })
                }}
                onSubmit={onSubmit}
              />
            }
          />
          <Route
            path="16"
            element={
              <BinaryTobacco
                onClick={tobaccoBoolean => {
                  setNewPatient({ ...newPatient, tobacco: tobaccoBoolean })
                  setIndexOfPage(indexOfPage => indexOfPage + 1)
                }}
              />
            }
          />
          <Route
            path="17"
            element={
              <Weight
                className="fs-block"
                value={newPatient.weight}
                onChange={e =>
                  setNewPatient({
                    ...newPatient,
                    weight: e.target.value,
                  })
                }
                onSubmit={onSubmit}
              />
            }
          />

          <Route
            path="18"
            element={
              <Height
                className="fs-block"
                value={newPatient.height}
                onChange={e =>
                  setNewPatient({
                    ...newPatient,
                    height: e.target.value,
                  })
                }
                onSubmit={onSubmit}
              />
            }
          />
          <Route
            path="creating"
            element={<WaitingComponent onSubmit={handleSubmit} />}
          />
          <Route path="/" element={<Redirect to="0" />} />
        </Routes>
      </div>

      {isLoading && <Spinner />}
      {notification && (
        <div className="text-white px-6 py-4 border-0 rounded absolute right-0 bottom-0 mb-4 bg-green-500">
          <span className="inline-block align-middle mr-8">{notification}</span>
        </div>
      )}
    </div>
  )
}

export default CreatePatient
